export class TableColumns {
  name: string;
  colSize?: number = 0;
  sortable?: boolean = true;
  filterable?: boolean = true;
  translate?: boolean = false;
  type?: string;
  format?: string;

  constructor(
    name: string,
    colSize?: number,
    sortable?: boolean,
    filterable?: boolean,
    translate?: boolean,
    type?: string | undefined,
    format?: string | undefined
  ) {
    this.name = name;
    this.colSize = colSize ?? 0;
    this.sortable = sortable ?? true;
    this.filterable = filterable ?? true;
    this.translate = translate ?? false;
    this.type = type;
    this.format = format;
  }

  static fromJson(json: TableColumns): TableColumns {
    return new TableColumns(
      json.name,
      json.colSize ?? 0,
      json.sortable ?? true,
      json.filterable ?? true,
      json.translate ?? true,
      json.type,
      json.format,
    );
  }
}
