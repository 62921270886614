<div class="table-features">
  <div class="flex title">
    <button *ngIf="backButton" [routerLink]="'/events'" class="back-button">
      <span class="icon">
        <mat-icon>
          <span class="material-symbols-outlined">arrow_back</span>
        </mat-icon>
      </span>
    </button>
    <p class="title-table">{{ tableTitle | translate }}</p>
  </div>
  <section class="features">
    <mat-form-field *ngIf="actionObjects.search" subscriptSizing="dynamic" appearance="outline" class="feature-button">
      <input matInput placeholder="Buscar" (input)="onFilter($event)" class="search-input" [disabled]="!isDataLoaded" />
      <mat-icon matPrefix>search</mat-icon>
    </mat-form-field>
    <div class="second-line">
      <button *ngIf="actionObjects.filter" mat-stroked-button class="feature-button" (click)="onCustomFilter()"
        [disabled]="!isDataLoaded">
        <span>Filtrar</span>
        <span class="icon">
          <mat-icon>
            <span class="material-symbols-outlined">filter_alt</span>
          </mat-icon>
        </span>
      </button>
      <button *ngIf="actionObjects.download" mat-stroked-button class="feature-button" [disabled]="!isDataLoaded">
        <span>Exportar</span>
        <span class="icon">
          <mat-icon>
            <span class="material-symbols-outlined">download</span>
          </mat-icon>
        </span>
      </button>
    </div>
    <button *ngIf="actionObjects.btnAdd" (click)="performAction(userActions.Add)" [disabled]="createButton"
      class="button-add rounded-4 bg-primary-50 flex items-center g-3">
      {{ addButtonText | translate }}
      <mat-icon>add</mat-icon>
    </button>
  </section>
</div>
<section *ngIf="tableDetails" class="table-details mb-6">
  <p class="detail-title pb-3">{{ tableDetails.title }}</p>
  <div class="detail-date">{{ tableDetails.eventStart | date:'shortDate' }} - {{ tableDetails.eventEnd |
    date:'shortDate' }}</div>
  <div class="detail-hour">{{ tableDetails.eventStart | date:'h:mm' }} - {{ tableDetails.eventEnd | date:'h:mm' }}</div>
  <app-table-card [event]="tableDetails"></app-table-card>
</section>
<section class="table-section" [hidden]="!isDataLoaded || !dataSource.data.length" #tableSection>
  <table mat-table matSort [dataSource]="dataSource" class="table" aria-describedby="ListData"
    *ngIf="tableTitle === 'products'; else noDragDrop" cdkDropList (cdkDropListDropped)="drop($event)"
    [cdkDropListDisabled]="isFilterActive()">
    <ng-container matColumnDef="checkbox">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox [checked]="isAllSelected()" [indeterminate]="isSomeSelected()" (change)="toggleAll()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let item">
        <mat-checkbox (change)="toggleSelection(item)" [checked]="selection.has(item)">
        </mat-checkbox>
      </td>
    </ng-container>
    <ng-container [matColumnDef]="column.name" *ngFor="let column of columnObjects">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [attr.colspan]="column.colSize"
        [ngClass]="{'id-column': isIdColumn(column.name)}">
        {{ column.name | translate }}
      </th>
      <td mat-cell *matCellDef="let item" [attr.colspan]="column.colSize" class="draggable-cell"
        [ngClass]="{'id-column': isIdColumn(column.name)}" [title]="(column.translate ? (formatValue(item[column.name], column.type, column.format) | translate) :
        formatValue(item[column.name], column.type, column.format))">
        {{ column.translate ? (formatValue(item[column.name], column.type, column.format) | translate) :
        formatValue(item[column.name], column.type, column.format) }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions" stickyEnd>
      <th mat-header-cell *matHeaderCellDef class="actions">
        <button class="button-delete" (click)="performAction(userActions.Delete)"
          [disabled]="selection.size === 0 || deleteButton">
          <mat-icon>delete</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let item">
        <div class="flex items-center g-3">
          <button mat-raised-button [matMenuTriggerFor]="menu" class="action-button">
            <span class="icon">
              <mat-icon class="edit-icon">
                <span class="material-symbols-outlined">edit</span>
              </mat-icon>
            </span>
          </button>
          <mat-menu #menu="matMenu">
            <button [disabled]="!item.unableEdit" mat-menu-item (click)="performAction(userActions.Update, item)">
              Editar
            </button>
          </mat-menu>
          <button cdkDragHandle class="drag-handle">
            <span class="icon">
              <mat-icon class="edit-icon">
                <span class="material-symbols-outlined">drag_handle</span>
              </mat-icon>
            </span>
          </button>
        </div>
      </td>
      <td mat-cell>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnNames; sticky: true" class="table-header"></tr>
    <tr mat-row *matRowDef="let row; columns: columnNames" class="table-rows" cdkDrag></tr>

    <!-- didnt find any data -->
    <ng-container *ngIf="actionObjects.search && isDataLoaded ">
      <tr *matNoDataRow>
        <td class="mat-cell" colspan="4">
          Nenhum resultado para "{{ dataSource.filter }}"
        </td>
      </tr>
    </ng-container>
  </table>
  <ng-template #noDragDrop>
    <table mat-table matSort [dataSource]="dataSource" class="table" aria-describedby="ListData">
      <ng-container matColumnDef="checkbox">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox [checked]="isAllSelected()" [indeterminate]="isSomeSelected()" (change)="toggleAll()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let item">
          <mat-checkbox (change)="toggleSelection(item)" [checked]="selection.has(item)">
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container [matColumnDef]="column.name" *ngFor="let column of columnObjects">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [attr.colspan]="column.colSize"
          [ngClass]="{'id-column': isIdColumn(column.name)}">
          {{ column.name | translate }}
        </th>
        <td mat-cell *matCellDef="let item" [attr.colspan]="column.colSize"
          [ngClass]="{'id-column': isIdColumn(column.name)}" [title]="(column.translate ? (formatValue(item[column.name], column.type, column.format) | translate) :
        formatValue(item[column.name], column.type, column.format))">
          {{ column.translate ? (formatValue(item[column.name], column.type, column.format) | translate) :
          formatValue(item[column.name], column.type, column.format) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions" stickyEnd>
        <th mat-header-cell *matHeaderCellDef class="actions">
          <button class="button-delete" (click)="performAction(userActions.Delete)"
            [disabled]="selection.size === 0 || deleteButton">
            <mat-icon>delete</mat-icon>
          </button>
        </th>
        <td mat-cell *matCellDef="let item" [ngClass]="tableTitle === 'events' ? 'multi-actions' : 'actions'">
          <button mat-raised-button *ngIf="tableTitle === 'events'" class="action-button"
            (click)="performAction(userActions.Detail, item)" [disabled]="!item.unableDetail">
            <span class="icon">
              <mat-icon class="edit-icon">
                <span class="material-symbols-outlined">list_alt</span>
              </mat-icon>
            </span>
          </button>
          <app-approve-buttons (participantUpdated)="updateEventDetails($event)" [item]="item"
            *ngIf="tableTitle === 'participation.requests'"></app-approve-buttons>
          <button mat-raised-button [matMenuTriggerFor]="menu" *ngIf="tableTitle !== 'participation.requests'"
            class="action-button">
            <span class="icon">
              <mat-icon class="edit-icon">
                <span class="material-symbols-outlined">edit</span>
              </mat-icon>
            </span>
          </button>
          <mat-menu #menu="matMenu">
            <button [disabled]="!item.unableEdit" mat-menu-item (click)="performAction(userActions.Update, item)">
              Editar
            </button>
          </mat-menu>
        </td>
        <td mat-cell>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="columnNames; sticky: true" class="table-header"></tr>
      <tr mat-row *matRowDef="let row; columns: columnNames" class="table-rows"></tr>
      <!-- didnt find any data -->
      <ng-container *ngIf="actionObjects.search && isDataLoaded ">
        <tr *matNoDataRow>
          <td class="mat-cell" colspan="4">
            Nenhum resultado para "{{ dataSource.filter }}"
          </td>
        </tr>
      </ng-container>
    </table>
  </ng-template>
</section>

<mat-paginator [ngStyle]="{ display: data.length > 0 ? 'block' : 'none' }" [length]="pageProperties.length"
  [pageSize]="pageProperties.pageSize" [pageSizeOptions]="pageSizeOptions" class="mat-paginator-sticky"
  showFirstLastButtons="true"></mat-paginator>

<ng-container *ngIf="isDataLoaded && !dataSource.data.length">
  <p class="empty-message">Não há {{ (tableTitle | translate) | lowercase }} cadastrados.</p>
</ng-container>

<ng-container *ngIf="!isDataLoaded">
  <table mat-table [dataSource]="generateFake(10)" aria-describedby="notloaded">
    <ng-container matColumnDef="checkbox">
      <th mat-header-cell *matHeaderCellDef>
        <ngx-skeleton-loader [theme]="{ width: '70px' }"></ngx-skeleton-loader>
      </th>
      <td mat-cell *matCellDef="let item">
        <ngx-skeleton-loader [theme]="{ width: '70px' }"></ngx-skeleton-loader>
      </td>
    </ng-container>
    <ng-container *ngFor="let column of columnObjects" [matColumnDef]="column.name">
      <th mat-header-cell *matHeaderCellDef>
        <ngx-skeleton-loader [theme]="{ width: '140px' }"></ngx-skeleton-loader>
      </th>
      <td mat-cell *matCellDef="let item">
        <ngx-skeleton-loader [theme]="{ width: '140px' }"></ngx-skeleton-loader>
      </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="actions">
        <ngx-skeleton-loader [theme]="{ width: '140px' }"></ngx-skeleton-loader>
      </th>
      <td mat-cell *matCellDef="let item" class="actions">
        <button mat-raised-button class="action-button" [disabled]="true">
          <span class="icon">
            <mat-icon class="edit-icon">
              <span class="material-symbols-outlined">edit</span>
            </mat-icon>
          </span>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnNames; sticky: true" class="table-header"></tr>
    <tr mat-row *matRowDef="let row; columns: columnNames" class="table-rows"></tr>
  </table>
</ng-container>
