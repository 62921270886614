<app-sidebar
  [menuItems]="menuItems"
  (sidebarStatus)="onSidebarStatusChanged($event)"
  #sidebar
></app-sidebar>
<div id="navbar" [class.active]="isActive">
  <div class="left">
    <app-button-menu
      aria-label="menu Menu"
      [isActive]="isActive"
      [color]="'#484649'"
      (click)="toggleSidebar()"
    ></app-button-menu>
  </div>
  <div class="right">
    <div class="notification">
      <a routerLink="/notifications">
        <app-svg-icon
          [iconName]="'notifications'"
          [fill]="false"
          [color]="'#484649'"
        ></app-svg-icon>
      </a>
    </div>
    <div class="profile">
      <a [matMenuTriggerFor]="profileMenu">
        <img
          alt="Person"
          [src]="profile.persons[0].picture"
          onerror="this.src='../../../assets/img/user.png'"
        />
        <span class="f-body-lg f-medium tx-neutral-20">{{ profile.persons[0].name }}</span>
      </a>
      <mat-menu #profileMenu="matMenu">
        <button mat-menu-item (click)="logout()">
          Sair
        </button>
      </mat-menu>
    </div>
  </div>
</div>
<div id="content" [class.active]="isActive">
  <ng-content></ng-content>
</div>
