<div class="clean-input flex direction-col g-3 pb-3">
  <label for="fileInput" *ngIf="!optional" class="label">{{ highlight ? ('file.highlight' | translate) : ('instructions'
    | translate) }}</label>
  <label data-test="txt" *ngIf="optional" class="label">{{ 'instructions' | translate }}
    <span style="color: var(--color-neutral-70);">{{ 'optional' | translate }}</span></label>
  <div style="position: relative;">
    <div class="upload-button flex items-center justify-center">
      <mat-icon class="upload-icon">upload_file</mat-icon>
      <p>
        {{ 'file.upload' | translate }}
      </p>
    </div>
    <input #fileInputRef id="fileInput" type='file' accept=".pdf" (change)="selectFile($event)">
    <p *ngIf="error" class="error-msg pt-2">{{ error }}</p>
  </div>
  <p class="details">{{ 'pdf.size.30' | translate }}</p>
  <div *ngFor="let p of files" class="flex">
    <div class="flex g-3">
      <p class="file-name">{{ p.title }}</p>
      <mat-icon class="done">done</mat-icon>
    </div>
    <mat-icon class="close" (click)="deleteFile(p)">close</mat-icon>
  </div>
</div>
