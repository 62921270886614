import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-custom-filter',
  templateUrl: './custom-filter.component.html',
  styleUrls: ['./custom-filter.component.scss']
})
export class CustomFilterComponent {

  constructor(
    public dialogRef: MatDialogRef<CustomFilterComponent>
  ) { }
  loadingSpinner = false;

  onSubmit() {
  }

  onCancel() {
    this.dialogRef.close();
  }

}
