<div class="flex g-5">
  <div class="table-card flex g-3 direction-col">
    <p class="table-card__title">{{ 'registrations.total' | translate }}</p>
    <p class="table-card__number">{{ event.registrations }}</p>
  </div>
  <div class="table-card blue flex g-3 direction-col">
    <p class="table-card__title">{{ 'vacancies.open' | translate }}</p>
    <p class="table-card__number">{{ event.open }}</p>
  </div>
  <div class="table-card green flex g-3 direction-col">
    <p class="table-card__title">{{ 'registrations.approved' | translate }}</p>
    <p class="table-card__number">{{ event.approved }}</p>
  </div>
  <div class="table-card red flex g-3 direction-col">
    <p class="table-card__title">{{ 'registrations.reproved' | translate }}</p>
    <p class="table-card__number">{{ event.disapproved }}</p>
  </div>
</div>
