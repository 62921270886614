import {
  Component,
  Input,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
  selector: 'app-simple-input',
  templateUrl: './simple-input.component.html',
  styleUrls: ['./simple-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SimpleInputComponent),
      multi: true,
    }
  ],
})
export class SimpleInputComponent implements ControlValueAccessor {

  public _value: any;
  public disabled!: boolean;
  @Input() label!: string;
  @Input() iconPath!: string;
  @Input() type!: string;
  @Input() placeholder!: string;
  @Input() value = null;
  @Input() optional = false;
  @Input() endIconPath?: string;
  @Input() valid!: boolean;
  @Input() hasIcon = true;
  @Input() readonly = false;
  @Input() invalidInputMsg!: string;
  hidePass = true;

  onChanged: any = () => {};
  onTouched: any = () => {};

  writeValue(val: any): void {
    this._value = val;
  }
  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  inputChange(e: any) {
    this.onTouched();
    this.onChanged(e.currentTarget.value);
  }

  exhibitPass() {
    this.hidePass = !this.hidePass;
    if (this.hidePass) {
      this.type = 'password';
    } else {
      this.type = 'text';
    }
  }
}
