import { MatPaginatorIntl } from '@angular/material/paginator';

export function CustomPaginator() {
  const paginatorIntl = new MatPaginatorIntl();

  const rangeLabel = (page: number, pageSize: number, length: number) => {
    if (length == 0 || pageSize == 0) return `0 van ${length}`;
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} de ${length}`;
  };

  paginatorIntl.itemsPerPageLabel = 'Itens por página:';
  paginatorIntl.nextPageLabel = 'Próxima página';
  paginatorIntl.previousPageLabel = 'Voltar página';
  paginatorIntl.getRangeLabel = rangeLabel;

  return paginatorIntl;
}
