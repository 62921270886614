<svg
  [attr.fill]="color"
  [attr.stroke]="color"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 -960 960 960"
  [attr.width]="size"
  [attr.height]="size"
>
  <path [attr.d]="iconPath"></path>
</svg>
