import { Injectable } from '@angular/core';

const TOKEN = 'token';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  clean(): void {
    window.localStorage.clear();
  }

  setToken(value: any): void {
    window.localStorage.removeItem(TOKEN);
    window.localStorage.setItem(TOKEN, value);
  }

  removeToken(): void {
    window.localStorage.removeItem(TOKEN);
  }

  getToken(): any {
    return window.localStorage.getItem(TOKEN);
  }

  isLoggedIn(): boolean {
    const value = window.localStorage.getItem(TOKEN);
    return !!value;
  }
}
