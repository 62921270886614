<p class="password-ul c-primary-50">{{ 'password.contain' | translate }}</p>
<ul aria-label="A senha deve conter:">
  <li class="flex items-center password-li pt-4 pb-2 g-3">
    <mat-icon *ngIf="passwordHasLength" class="check-icon">check</mat-icon>
    <mat-icon *ngIf="!passwordHasLength" color="warn">close</mat-icon>
    <p>
      {{ 'password.contain.char' | translate }}
    </p>
  </li>
  <li class="flex items-center password-li pt-2 pb-2 g-3">
    <mat-icon *ngIf="passwordHasNumber" class="check-icon">check</mat-icon>
    <mat-icon *ngIf="!passwordHasNumber" color="warn">close</mat-icon>
    <p>
      {{ 'password.contain.number' | translate }}
    </p>
  </li>
  <li class="flex items-center password-li pt-2 pb-2 g-3">
    <mat-icon *ngIf="passwordHasLetter" class="check-icon">check</mat-icon>
    <mat-icon *ngIf="!passwordHasLetter" color="warn">close</mat-icon>
    <p>
      {{ 'password.contain.upper' | translate }}
    </p>
  </li>
  <li class="flex items-center password-li pt-2 pb-2 g-3">
    <mat-icon *ngIf="passwordHasSpecialChar" class="check-icon">check</mat-icon>
    <mat-icon *ngIf="!passwordHasSpecialChar" color="warn">close</mat-icon>
    <p>
      {{ 'password.contain.special' | translate }}
    </p>
  </li>
</ul>
