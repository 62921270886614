<div class="flex" [class.full-width]="!time">
  <mat-form-field floatLabel="always" subscriptSizing="dynamic" class="clean-input date-input-field">
    <label class="label">
      <span *ngIf="optional">{{ title | translate }} <span style="color: var(--color-neutral-70);">{{ 'optional' | translate }}</span></span>
      <span *ngIf="!optional">{{ title | translate }}</span>
    </label>
    <input matInput [matDatepicker]="picker" (dateInput)="selectDate($event)" [placeholder]="placeholder | translate"
      [value]="value" readonly>
    <mat-datepicker-toggle matIconSuffix [for]="picker">
      <mat-icon matDatepickerToggleIcon>expand_more</mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>

  <mat-form-field floatLabel="always" subscriptSizing="dynamic" class="clean-input time-input-field" *ngIf="time">
    <label class="label">
      <span>{{ 'hour' | translate }}</span>
    </label>
    <input matInput [placeholder]="'hour.type' | translate" maxlength="5" [id]="hourInputId"
      (input)="onInputChange($event)" pattern="^([01]?[0-9]|2[0-3]):[0-5][0-9]$">
  </mat-form-field>
</div>
<div *ngIf="valid" class="alert">
  {{ invalidInputMsg }}
</div>
