import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '@model/api-response.model';
import { Me } from '@model/ws-user';
import { Observable, of, shareReplay, tap } from 'rxjs';

import { environment } from 'src/environments/environment';

const URL_ME = `${environment.user}/me`;

@Injectable({
  providedIn: 'root'
})
export class MeService {
  private meCache$?: Observable<ApiResponse<Me>>;

  constructor(private http: HttpClient) { }

  getMeCache(): Observable<ApiResponse<Me>> {
    if (!this.meCache$) {
      this.meCache$ = this.getMeFromApi().pipe(
        shareReplay(1)
      );
    }
    return this.meCache$;
  }

  getMeFromApi(): Observable<ApiResponse<Me>> {
    return this.http.get<ApiResponse<Me>>(URL_ME, { withCredentials: true }).pipe(
      tap(data => {
        this.meCache$ = of(data);
      }));
  }

  clearCache(): void {
    this.meCache$ = undefined;
  }

}
