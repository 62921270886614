import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';

import { CustomTableComponent } from './custom-table/custom-table.component';
import { FooterComponent } from './footer/footer.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { FilterTableModule } from '../pipes/filter-table/filter-table.module';
import { SidebarComponent } from './navbar/sidebar/sidebar.component';
import { CustomFilterComponent } from './custom-filter/custom-filter.component';
import { NavbarComponent } from './navbar/navbar.component';
import { ButtonMenuComponent } from './navbar/button-menu/button-menu.component';
import { SvgIconComponent } from './svg-icon/svg-icon.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { SimpleInputComponent } from './simple-input/simple-input.component';
import { DateInputComponent } from './date-input/date-input.component';
import { TranslateModule } from '@ngx-translate/core';
import { SelectInputComponent } from './select-input/select-input.component';
import { CountryInputComponent } from './country-input/country-input.component';
import { MultiSelectInputComponent } from './multi-select-input/multi-select-input.component';
import { PasswordCheckComponent } from './password-check/password-check.component';
import { SlideToggleInputComponent } from './slide-toggle-input/slide-toggle-input.component';
import { RadioButtonsInputComponent } from './radio-buttons-input/radio-buttons-input.component';
import { UploadImageInputComponent } from './upload-image-input/upload-image-input.component';
import { AutocompleteInputComponent } from './autocomplete-input/autocomplete-input.component';
import { UploadFileInputComponent } from './upload-file-input/upload-file-input.component';
import { ScheduleInputComponent } from './schedule-input/schedule-input.component';
import { TableCardComponent } from './table-card/table-card.component';
import { ApproveButtonsComponent } from './approve-buttons/approve-buttons.component';
import { DialogDropdownComponent } from './dialog-dropdown/dialog-dropdown.component';
import { QuillEditorComponent } from './quill-editor/quill-editor.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { KeywordInputComponent } from './keyword-input/keyword-input.component';

@NgModule({
  declarations: [
    NavbarComponent,
    SidebarComponent,
    ButtonMenuComponent,
    SvgIconComponent,
    TooltipComponent,
    FooterComponent,
    ConfirmationDialogComponent,
    CustomTableComponent,
    CustomFilterComponent,
    SimpleInputComponent,
    DateInputComponent,
    SelectInputComponent,
    CountryInputComponent,
    MultiSelectInputComponent,
    PasswordCheckComponent,
    SlideToggleInputComponent,
    RadioButtonsInputComponent,
    UploadImageInputComponent,
    AutocompleteInputComponent,
    UploadFileInputComponent,
    ScheduleInputComponent,
    TableCardComponent,
    ApproveButtonsComponent,
    DialogDropdownComponent,
    QuillEditorComponent,
    KeywordInputComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatMenuModule,
    MatSidenavModule,
    MatSortModule,
    MatFormFieldModule,
    MatTableModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatOptionModule,
    MatSelectModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatRadioModule,
    NgxSkeletonLoaderModule,
    FormsModule,
    ReactiveFormsModule,
    FilterTableModule,
    MatSlideToggleModule,
    TranslateModule,
    DragDropModule
  ],
  exports: [
    NavbarComponent,
    SvgIconComponent,
    TooltipComponent,
    FooterComponent,
    CustomTableComponent,
    SimpleInputComponent,
    DateInputComponent,
    SelectInputComponent,
    CountryInputComponent,
    MultiSelectInputComponent,
    PasswordCheckComponent,
    SlideToggleInputComponent,
    RadioButtonsInputComponent,
    UploadImageInputComponent,
    AutocompleteInputComponent,
    UploadFileInputComponent,
    ScheduleInputComponent,
    DialogDropdownComponent,
    QuillEditorComponent,
    KeywordInputComponent
  ]
})
export class ComponentsModule { }
