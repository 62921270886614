import { Component, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { AuthService } from 'src/app/services/ws-user/auth/auth.service';

@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.scss']
})
export class PasswordRecoveryComponent {

  @Output() onLogin = new EventEmitter();
  awaitingResponse = false;
  passwordForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email])
  });

  constructor(private authService: AuthService, private translate: TranslateService, private snackBar: MatSnackBar) { }

  backToLogin() {
    this.onLogin.emit(true);
  }

  onSubmit() {
    this.awaitingResponse = true;
    this.authService.forgotPassword(this.passwordForm.getRawValue().email!).subscribe({
      next: (response: any) => {
        this.snackBar.open(response.data, this.translate.instant('close'), { duration: 5000 });
        this.awaitingResponse = false;
      },
      error: (er) => {
        this.awaitingResponse = false;
        this.snackBar.open(er.error.errors[0], this.translate.instant('close'), { duration: 5000 });
      }
    }
    );
  }
}
