import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-radio-buttons-input',
  templateUrl: './radio-buttons-input.component.html',
  styleUrl: './radio-buttons-input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioButtonsInputComponent),
      multi: true,
    },
  ],
})
export class RadioButtonsInputComponent implements ControlValueAccessor {
  public _value: any;
  public disabled: boolean = false;
  selected!: any;
  @Input() label: any;
  @Input() data: any;
  @Input() product: any;
  @Input() procedure: any;
  @Output() optionSelected = new EventEmitter<string>();

  onChanged: any = () => { };
  onTouched: any = () => { };

  writeValue(val: any): void {
    this.data.forEach((e: any) => {
    });

    this._value = val;
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  emitChange(value: any): void {
    this.onChanged(value);
    this.onTouched();
    this.optionSelected.emit(value);
  }
}
