import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button-menu',
  styleUrl: './button-menu.component.scss',
  templateUrl: './button-menu.component.html'
})
export class ButtonMenuComponent {
  @Input() isActive: boolean = false;
  @Input() animation: boolean = true;
  @Input() size: string = '1.5rem';
  @Input() color: string = '#484649';
}
