import { Component, OnInit } from '@angular/core';

import { MenuItem } from '@interface/menu-item.interface';
import { Profile } from '@model/profile.model';
import { MeService } from '../services/ws-user/me/me.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PermissionService } from '../services/permission/permission.service';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
})
export class PanelComponent implements OnInit {
  me?: any;
  profile: Profile = {
    name: 'Usuário',
    picture: '../../../assets/img/user.png'
  }
  isLoaded = false;

  menuItems: MenuItem[] = [
    {
      Name: 'Início',
      Icon: 'home',
      Route: '/home'
    },
    {
      Name: 'Produtos',
      Icon: 'inventory_2',
      Route: '/products'
    },
    {
      Name: 'Eventos',
      Icon: 'event',
      Route: '/events'
    },
    {
      Name: 'Categorias',
      Icon: 'list-all',
      Route: '/categories'
    },
    {
      Name: 'Conteúdos',
      Icon: 'folder',
      Route: '/contents'
    },
    {
      Name: 'Usuários',
      Icon: 'group',
      Route: '/users'
    },
  ];

  constructor(private meService: MeService, private snackBar: MatSnackBar, private permissionService: PermissionService) { }

  ngOnInit(): void {
    this.meService.getMeCache().subscribe({
      next: (res) => {
        this.me = res.data;
        this.menuItems = this.menuItems.filter(item =>
          this.permissionService.getUserPermission(item.Route, this.me));
        this.isLoaded = true;
      },
      error: (er) => this.snackBar.open(er.error.errors[0])
    });
  }
}
