import { AfterViewInit, Component, Input, OnInit, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-schedule-input',
  templateUrl: './schedule-input.component.html',
  styleUrl: './schedule-input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ScheduleInputComponent),
      multi: true,
    }
  ],
})
export class ScheduleInputComponent implements ControlValueAccessor, AfterViewInit, OnInit {

  schedules: any[] = [];
  public _value: any;
  public disabled!: boolean;
  changes = false;
  @Input() value: any = null;
  @Input() valid = false;
  hourInputId = Math.random().toString(36).substr(2, 9);

  onChanged: any = () => { };
  onTouched: any = () => { };

  ngOnInit(): void {
    this.addScheduleElement();
  }

  touched = false;
  touch() {
    this.touched = true;
  }

  addScheduleElement() {
    this.schedules.push({
      id: null,
      startDate: null,
      startHour: null,
      endDate: null,
      endHour: null,
      scheduleEnd: null,
      scheduleStart: null,
      languages: [{
        id: null,
        title: null,
        description: null,
        languageCode: 'pt-BR',
      }]
    });
    this.changes = !this.changes;
  }

  removeScheduleElement(i: any) {
    this.schedules.splice(i, 1);
    this.changes = this.changes;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this._value) {
        this.schedules = this._value

        this.extractHoursAndMinutes();
        this.onChanged(this.schedules);
      }
    });
  }
  writeValue(val: any): void {
    this._value = val;
  }
  registerOnChange(fn: (option: any) => void): void {
    this.onChanged = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  extractHoursAndMinutes() {
    this.schedules.forEach((s) => {
      const dateEnd = new Date(s.scheduleEnd);

      const hoursEnd = dateEnd.getHours();
      const minutesEnd = dateEnd.getMinutes();
      const formattedHoursE = hoursEnd < 10 ? '0' + hoursEnd : hoursEnd.toString();
      const formattedMinutesE = minutesEnd < 10 ? '0' + minutesEnd : minutesEnd.toString();
      s.endHour = formattedHoursE + ':' + formattedMinutesE;
      s.endDate = this.formatDate(dateEnd);

      const dateStart = new Date(s.scheduleStart);
      const hoursStart = dateStart.getHours();
      const minutesStart = dateStart.getMinutes();

      const formattedHours = hoursStart < 10 ? '0' + hoursStart : hoursStart.toString();
      const formattedMinutes = minutesStart < 10 ? '0' + minutesStart : minutesStart.toString();
      s.startHour = formattedHours + ':' + formattedMinutes;
      s.startDate = this.formatDate(dateStart);

    });
  }
  formatDate(date: any): string {
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-based, so we add 1
    const year = date.getFullYear();

    // Add leading zeros if necessary
    const formattedDay = day < 10 ? '0' + day : day.toString();
    const formattedMonth = month < 10 ? '0' + month : month.toString();

    return formattedDay + '/' + formattedMonth + '/' + year;
}

  setSchedule(object: any) {
    const { endHour, endDate, startHour, startDate } = object;

    if (endHour && endDate) {
      const [hour, minute] = endHour.split(':');
      const [day, month, year] = endDate.split('/');
      const scheduleEnd = new Date(`${year}-${month}-${day}T${hour}:${minute}:00`).toISOString();
      object.scheduleEnd = scheduleEnd;
    }

    if (startHour && startDate) {
      const [hour, minute] = startHour.split(':');
      const [day, month, year] = startDate.split('/');
      const scheduleStart = new Date(`${year}-${month}-${day}T${hour}:${minute}:00`).toISOString();
      object.scheduleStart = scheduleStart;
    }
    this.onChanged(this.schedules);
  }

  onHourInputChange(item: any, event: any, start: boolean) {
    let sanitizedValue = event.target.value.replace(/\D/g, '');
    if (sanitizedValue.length > 4) {
      sanitizedValue = sanitizedValue.substring(0, 4);
    }
    if (sanitizedValue.length > 2) {
      sanitizedValue = sanitizedValue.substring(0, 2) + ':' + sanitizedValue.substring(2);
    }
    event.target.value = sanitizedValue;
    if (start) {
      item.startHour = sanitizedValue;
    } else {
      item.endHour = sanitizedValue;
    }
    this.checkDateParts(item);
  }

  private checkDateParts(item: any) {
    if (item.startDate && item.startHour && item.endDate && item.endHour) {
      const startDateParts = item.startDate.split('/');
      const endDateParts = item.endDate.split('/');
      const startHourParts = item.startHour.split(':');
      const endHourParts = item.endHour.split(':');

      if (startDateParts[2]?.length === 4 && endDateParts[2]?.length === 4
        && startHourParts[1]?.length === 2 && endHourParts[1]?.length === 2) {
        this.setSchedule(item);
      }
    }
  }

  onDateInputChange(item: any, event: any, start: boolean) {
    let sanitizedValue = event.target.value.replace(/\D/g, '');
    if (sanitizedValue.length > 8) {
      sanitizedValue = sanitizedValue.substring(0, 8);
    }
    if (sanitizedValue.length > 2 && sanitizedValue.length <= 4) {
      sanitizedValue = sanitizedValue.substring(0, 2) + '/' + sanitizedValue.substring(2);
    } else if (sanitizedValue.length > 4) {
      sanitizedValue = sanitizedValue.substring(0, 2) + '/' + sanitizedValue.substring(2, 4) + '/' + sanitizedValue.substring(4);
    }
    event.target.value = sanitizedValue;
    if (start) {
      item.startDate = sanitizedValue;
    } else {
      item.endDate = sanitizedValue;
    }

    this.checkDateParts(item);
  }
}
