import { Component, Input, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';


import { SidebarComponent } from './sidebar/sidebar.component';
import { MenuItem } from '@interface/menu-item.interface';
import { AuthService } from 'src/app/services/ws-user/auth/auth.service';
import { Router } from '@angular/router';
import { TokenStorageService } from 'src/app/services/token/token-storage.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { MeService } from 'src/app/services/ws-user/me/me.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})
export class NavbarComponent {
  @ViewChild('sidebar') sidebar!: SidebarComponent;
  @Input() profile?: any;
  @Input() menuItems: MenuItem[] | undefined;

  isActive: boolean = false;
  profileName: string = 'User';
  profileImage: string = '../../assets/images/person-dark.svg';

  constructor(private authService: AuthService, private router: Router, private tokenStorage: TokenStorageService,
    private snackBar: MatSnackBar, private translate: TranslateService, private meService: MeService) {}
  toggleSidebar() {
    this.sidebar.toggle();
  }

  onSidebarStatusChanged(isActive: boolean) {
    this.isActive = isActive;
  }

  logout() {
    this.authService.logout().subscribe({
      next: (response: any) => {
        this.snackBar.open(response.data, this.translate.instant('close'), { duration: 5000 });
        this.tokenStorage.removeJwt();
        this.meService.clearCache();
        this.router.navigate(['/login']);
      },
      error: (er) => {
        this.snackBar.open(er.error.errors[0], this.translate.instant('close'), { duration: 5000 });
      }
    });
  }
}
