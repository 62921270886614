import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-password-check',
  templateUrl: './password-check.component.html',
  styleUrl: './password-check.component.scss'
})
export class PasswordCheckComponent implements OnChanges {
  @Input() password = '';
  passwordHasLetter = false;
  passwordHasLength = false;
  passwordHasNumber = false;
  passwordHasSpecialChar = false;
  passwordEquals = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['password'].currentValue) {
    this.passwordHasNumber = /\d/.test(this.password);
    this.passwordHasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~]/.test(this.password);
    }

    if (changes['password'].currentValue) {
      this.verifyPassword();
    }
  }

  verifyPassword() {
    this.passwordHasLength = this.password.length >= 8;
    this.passwordHasLetter = /^(?=.*[a-z])(?=.*[A-Z]).+$/.test(this.password);
    this.passwordHasNumber = /\d/.test(this.password);
    this.passwordHasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~]/.test(this.password);
  }
}
