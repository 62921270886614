<div class="flex g-3 justify-center">
  <button mat-raised-button class="action-button" [disabled]="loadingSpinner"
    (click)="performAction(userActions.Approve)"
    [ngClass]="{'green-bg': item.requestParticipant === 0}">
    <span class="icon">
      <mat-icon class="edit-icon" *ngIf="!loadingSpinner" [ngClass]="{'white-icon': item.requestParticipant === 0}">thumb_up</mat-icon>
    </span>
    <mat-spinner *ngIf="loadingSpinner"></mat-spinner>
  </button>
  <button mat-raised-button class="action-button" [disabled]="loadingSpinner"
    (click)="performAction(userActions.Reprove)"
    [ngClass]="{'red-bg': (item.requestParticipant !== 0 && item.requestParticipant !== 2)}">
    <span class="icon">
      <mat-icon class="edit-icon" *ngIf="!loadingSpinner" [ngClass]="{'white-icon': (item.requestParticipant !== 0 && item.requestParticipant !== 2)}">thumb_down</mat-icon>
    </span>
    <mat-spinner *ngIf="loadingSpinner"></mat-spinner>
  </button>
  <button mat-raised-button class="action-button delete-button" [disabled]="loadingSpinner"
    (click)="performAction(userActions.Delete)">
    <span class="icon">
      <mat-icon class="edit-icon" *ngIf="!loadingSpinner">delete</mat-icon>
    </span>
    <mat-spinner *ngIf="loadingSpinner"></mat-spinner>
  </button>
</div>
