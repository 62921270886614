import { registerLocaleData } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import localePtBr from '@angular/common/locales/pt';
import localePt from '@angular/common/locales/pt';
import localeEs from '@angular/common/locales/es';
import localeEn from '@angular/common/locales/en';


@Injectable({
  providedIn: 'root'
})
export class LocaleService {

  locale: string;

  constructor(@Inject(LOCALE_ID) private localeId: string) {
    this.locale = localeId;
    this.setLocale(this.locale);
  }

  setLocale(localeId: any): void {
    switch (localeId) {
      case 'pt-BR':
        this.locale = 'pt-BR';
        registerLocaleData(localePtBr);
        break;
      case 'es':
        this.locale = 'es-ES';
        registerLocaleData(localeEs);
        break;
      case 'pt':
        this.locale = 'pt';
        registerLocaleData(localePt);
        break;
      default:
        this.locale = 'en-US';
        registerLocaleData(localeEn);
        break;
    }
  }

  getLocale() {
    return this.locale;
  }
}
