<div class="mt-6 flex direction-col items-center" style="position: relative;">
  <mat-dialog-content>
    <button mat-button data-test="btn" class="close-button" [mat-dialog-close]="false">{{ 'close' | translate
      }}</button>
    <p class="message pb-6 pt-4">{{data.message}}</p>
    <app-select-input [label]="'reason.reprove' | translate" [data]="options" [translation]="true"
      [placeholder]="'select.reason' | translate" (onSelect)="select($event)"></app-select-input>
  </mat-dialog-content>
  <mat-dialog-actions style="width: 100%;">
    <button mat-button data-test="btn" class="save-button" [mat-dialog-close]="selectedOption"
      [disabled]="!selectedOption">{{ 'reprove' |
      translate }}</button>
  </mat-dialog-actions>
</div>
