<mat-form-field floatLabel="always" subscriptSizing="dynamic" class="clean-input">
  <label class="label">{{ label }}</label>
  <mat-select [formControl]="toppings" [placeholder]="placeholder" (selectionChange)="selectOption()" [disabled]="disabled">
    @for (d of data; track d) {
    <mat-option *ngIf="d.title" [value]="d">{{translation ? (d.title | translate) : d.title}}</mat-option>
    <mat-option *ngIf="d.name" [value]="d">{{translation ? (d.name | translate) : d.name}}</mat-option>
    }
  </mat-select>
  <mat-icon matSuffix>expand_more</mat-icon>
</mat-form-field>

<div *ngIf="valid" class="alert">
  {{ 'invalid.input' | translate }}
</div>
