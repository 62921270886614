import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogData } from '@model/dialogs/dialog-data';

@Component({
  selector: 'app-dialog-dropdown',
  templateUrl: './dialog-dropdown.component.html',
  styleUrl: './dialog-dropdown.component.scss'
})
export class DialogDropdownComponent {
  selectedOption: any;
  options: any[] = [
    { "id": 1, "title": "disapproved" },
    { "id": 3, "title": "canceled" },
    { "id": 4, "title": "withdrawal" },
    { "id": 5, "title": "changed" },
    { "id": 6, "title": "problem" },
    { "id": 7, "title": "capacity" }
  ];

  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData) { }

  select(e: any) {
    this.selectedOption = e;
  }
}
