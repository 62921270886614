<mat-card class="flex justify-center w-full h-full login">
  <mat-card-header class="login">
    <img alt="Síntegra Surgical Sciences" src="../../../assets/img/logo.svg">
    <h1>{{ 'password.recover' | translate }}</h1>
    <p class="pb-6">{{ 'email.type' | translate }}</p>
  </mat-card-header>
  <mat-card-content>
    <form class="form" [formGroup]="passwordForm">
      <mat-form-field appearance="fill">
        <mat-label>E-mail</mat-label>
        <input matInput type="text" formControlName="email">
      </mat-form-field>

    </form>
  </mat-card-content>
  <mat-card-actions>
    <button mat-button class="submit-button" type="submit" for="form" [disabled]="!passwordForm.valid"
      (click)="onSubmit()" *ngIf="!awaitingResponse">{{ 'password.recover' | translate }}</button>
    <button mat-button class="submit-button loading" disabled (click)="onSubmit()" *ngIf="awaitingResponse">
      <mat-spinner></mat-spinner>
    </button>
    <div class="recovery-button pt-4">
      <button mat-button type="button" (click)="backToLogin()">{{ 'login.back' | translate }}</button>
    </div>
  </mat-card-actions>
</mat-card>
