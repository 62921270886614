import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { TokenStorageService } from 'src/app/services/token/token-storage.service';

export const authGuard: CanActivateFn = async () => {
  const router: Router = inject(Router);
  const tokenService: TokenStorageService = inject(TokenStorageService);
  if (tokenService.getJwt()) {
    return true;
  } else {
    router.navigate(['/login']);
    return false;
  }
};
