import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-select-input',
  templateUrl: './select-input.component.html',
  styleUrl: './select-input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectInputComponent),
      multi: true,
    },
  ],
})
export class SelectInputComponent implements OnInit, ControlValueAccessor {
  @Input() toppings = new FormControl<any>(null);
  @Input() name!: string;
  @Input() label!: string;
  @Input() placeholder!: string;
  @Input() data: any[] = [];
  @Input() valid!: boolean;
  @Input() translation = false;
  @Output() onSelect: EventEmitter<any> = new EventEmitter<any>();

  public _value: any;
  public disabled: boolean = false;

  ngOnInit(): void {
    const defaultValue = this.data.filter((d: any) => d.id === this.toppings.getRawValue());
    this.toppings.setValue(defaultValue[0]);
  }

  onChanged: any = () => { };
  onTouched: any = () => { };

  writeValue(val: any): void {
    this._value = val;
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  selectOption() {
    const selected = (this.toppings.getRawValue());
    if (selected) {
      this.writeValue(selected.id);
      this.onChanged(selected.id);
      this.onTouched();
      this.onSelect.emit(selected);
    }
  }

}
