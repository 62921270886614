import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { UserActions } from '@model/enums/UserActions';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'src/app/services/dialogs/dialog.service';
import { EventManageService } from 'src/app/services/ws-event/event-manage/event-manage.service';
import { DialogDropdownComponent } from '../dialog-dropdown/dialog-dropdown.component';

@Component({
  selector: 'app-approve-buttons',
  templateUrl: './approve-buttons.component.html',
  styleUrl: './approve-buttons.component.scss'
})
export class ApproveButtonsComponent {
  @Input() item!: any;
  readonly userActions: typeof UserActions = UserActions;
  loadingSpinner = false;
  @Output() participantUpdated = new EventEmitter<any>();

  constructor(private eventManageService: EventManageService, private dialog: DialogService, private translate: TranslateService, private snackBar: MatSnackBar, private dialogReprove: MatDialog) { }

  performAction(action: any) {
    switch (action) {
      case UserActions.Approve:
        if (this.item.requestParticipant === 0) return;
        this.approveParticipant();
        break;
      case UserActions.Reprove:
        if (this.item.requestParticipant === 1) return;
        this.onReprove();
        break;
      case UserActions.Delete:
        this.onDelete();
        break;
      default:
        break;
    }
  }

  approveParticipant() {
    this.loadingSpinner = true;
    this.eventManageService.updateEventParticipant({ id: this.item.participantId, requestParticipant: 0, description: '' }).subscribe({
      next: () => {
        this.item.requestParticipant = 0;
        this.loadingSpinner = false;
        this.participantUpdated.emit({ action: UserActions.Approve, item: this.item });
      },
      error: (er) => {
        this.snackBar.open(er.error.errors[0], this.translate.instant('close'), { duration: 5000 });
        this.loadingSpinner = false;
      }
    });
  }

  reproveParticipant(confirmed: any) {
    this.loadingSpinner = true;
    this.eventManageService.updateEventParticipant({ id: this.item.participantId, requestParticipant: confirmed.id, description: confirmed.title }).subscribe({
      next: () => {
        this.item.requestParticipant = 1;
        this.loadingSpinner = false;
        this.participantUpdated.emit({ action: UserActions.Reprove, item: this.item });
      },
      error: (er) => {
        this.snackBar.open(er.error.errors[0], this.translate.instant('close'), { duration: 5000 });
        this.loadingSpinner = false;
      }
    });
  }

  deleteParticipant() {
    this.eventManageService.deleteEventParticipant(this.item.participantId).subscribe({
      next: () => {
        this.snackBar.open(this.translate.instant('participant.request.delete.success'), this.translate.instant('close'), { duration: 5000 });
        this.loadingSpinner = false;
        this.participantUpdated.emit({ action: UserActions.Delete, item: this.item });
      },
      error: (er) => {
        this.snackBar.open(er.error.errors[0], this.translate.instant('close'), { duration: 5000 });
        this.loadingSpinner = false;
      }
    });
  }

  onDelete(): void {
    this.dialog.confirmDialog({
      title: this.translate.instant('participant.request.delete'),
      message: this.translate.instant('participant.request.delete'),
      confirmCaption: this.translate.instant('yes'),
      cancelCaption: this.translate.instant('no'),
    })
      .subscribe((yes) => {
        if (yes) {
          this.loadingSpinner = true;
          this.deleteParticipant();
        }
      });
  }

  onReprove(): void {
    const dialogRef = this.dialogReprove.open(DialogDropdownComponent, {
      data: {
        message: this.translate.instant('participant.reprove.confirm'),
        confirmCaption: this.translate.instant('yes'),
        cancelCaption: this.translate.instant('no'),
      },
      width: '400px',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed !== false) {
        this.loadingSpinner = true;
        this.reproveParticipant(confirmed);
      }
    });
  }
}
