import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import { EventResponse, TableEventResponseList } from '@model/ws-eventshub/event.model';

const URL_MANAGE = `${environment.event}/manage/eventhub`;
const URL_EVENT = `${environment.event}/eventhub`;
const URL_PARTICIPANT = `${environment.event}/manage/participant`;

@Injectable({
  providedIn: 'root'
})
export class EventManageService {

  constructor(private http: HttpClient) { }

  getEvents(): Observable<TableEventResponseList> {
    return this.http.get<TableEventResponseList>(`${URL_MANAGE}/?PageSize=0`, { withCredentials: true });
  }

  getEventById(id: string): Observable<EventResponse> {
    return this.http.get<EventResponse>(`${URL_MANAGE}/${id}`, { withCredentials: true });
  }

  getEventParticipants(id: string): Observable<any> {
    return this.http.get<any>(`${URL_MANAGE}/${id}/participant`, { withCredentials: true });
  }

  updateEventParticipant(participant: any): Observable<any> {
    return this.http.put<any>(`${URL_PARTICIPANT}/${participant.id}`, participant, { withCredentials: true });
  }

  deleteEventParticipant(id: string): Observable<any> {
    return this.http.delete<any>(`${URL_PARTICIPANT}/${id}`, { withCredentials: true });
  }

  registerEvent(event: any): Observable<any> {
    return this.http.post<any>(URL_MANAGE, event, { withCredentials: true });
  }

  updateEvent(event: any): Observable<any> {
    return this.http.put<any>(`${URL_MANAGE}/${event.id}`, event, { withCredentials: true });
  }

  deleteEvent(id: string): Observable<any> {
    return this.http.delete<any>(`${URL_MANAGE}/${id}`, { withCredentials: true });
  }

  linkEventsToContent(obj: any): Observable<any> {
    return this.http.put<any>(`${URL_EVENT}/content/${obj.contentId}`, obj, { withCredentials: true });
  }

  getLinkedEvents(contentId: string): Observable<any> {
    return this.http.get<any>(`${URL_EVENT}/content/${contentId}`, { withCredentials: true });
  }
}
