import { Component, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-keyword-input',
  templateUrl: './keyword-input.component.html',
  styleUrl: './keyword-input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => KeywordInputComponent),
      multi: true,
    }
  ],
})
export class KeywordInputComponent implements ControlValueAccessor {

  public _value: any;
  public disabled!: boolean;
  @Input() placeholder!: string;
  @Input() value = null;
  @Input() keywords: string[] = [];
  @ViewChild('input') input!: ElementRef;

  onChanged: any = () => { };
  onTouched: any = () => { };

  writeValue(val: any): void {
    this._value = val;
  }
  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  addKeyword(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.keywords.push(value);
    }
    this.onTouched();
    this.onChanged(this.keywords);
    event.chipInput!.clear();
  }

  deleteKeyword(k: any) {
    this.keywords = this.keywords.filter(c => c !== k);
    this.onTouched();
    this.onChanged(this.keywords);
  }
}
