import { Pipe, PipeTransform } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Pipe({
  name: 'filterTable'
})
export class FilterTablePipe implements PipeTransform {

  /*Impleementar debounce*/
  transform(dataSource: MatTableDataSource<any>, value: string): MatTableDataSource<any> {
    const filterValue = value.trim().toLowerCase();
    dataSource.filter = filterValue;
    dataSource.paginator?.firstPage();
    return dataSource;
  }
}
