import { Component, ElementRef, Input, ViewChild, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'src/app/services/dialogs/dialog.service';

@Component({
  selector: 'app-upload-file-input',
  templateUrl: './upload-file-input.component.html',
  styleUrl: './upload-file-input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UploadFileInputComponent),
      multi: true,
    }
  ],
})
export class UploadFileInputComponent implements ControlValueAccessor {

  @ViewChild('fileInputRef', { static: false }) fileInput!: ElementRef;
  files: any[] = [];
  error = '';
  @Input() highlight = false;
  @Input() optional = false;

  private onChange: any = () => { };
  private onTouched: any = () => { };

  constructor(private translate: TranslateService, private dialog: DialogService) { }

  selectFile(e: any) {
    if (e.target.files[0].size > 30 * 1024 * 1024) {
      this.error = this.translate.instant('file.size.invalid');
      this.onChange(null);
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = (event) => {
      if (event.target?.result) {
        let str = event.target?.result as string;

        if (!str.startsWith("data:application/pdf")) {
          this.error = this.translate.instant('file.type.not');
          this.onChange(null);
        } else {
          this.files.push({ file: event.target?.result, title: this.fileInput.nativeElement.files[0].name });
          this.error = '';
          this.onChange(this.files);
        }
      }
    }
  }

  deleteFile(file: any) {
    this.dialog.confirmDialog({
      title: this.translate.instant('file.delete'),
      message: this.translate.instant('file.delete'),
      confirmCaption: this.translate.instant('yes'),
      cancelCaption: this.translate.instant('no'),
    })
      .subscribe((yes) => {
        if (yes) {
          const index = this.files.indexOf(file);
          if (index !== -1) {
            this.files.splice(index, 1);
          }
          this.onChange(this.files);
          this.fileInput.nativeElement.value = '';
        }
      });

  }

  writeValue(obj: any): void {
    if (obj) {
      this.files = obj;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void { }
}
