<mat-form-field floatLabel="always" subscriptSizing="dynamic" class="clean-input mb-3">
  <label data-test="lbl">
    <span data-test="txt" class="label">{{ 'keywords' | translate }}
      <span style="color: var(--color-neutral-70);">{{ 'optional' | translate }}</span>
    </span>
  </label>
  <mat-chip-grid #keywordGrid aria-label="city selection">
    @for (keyword of keywords; track keyword) {
    <mat-chip-row (removed)="deleteKeyword(keyword)">
      {{ keyword }}
      <button matChipRemove [attr.aria-label]="'remove ' + keyword">
        <mat-icon>close</mat-icon>
      </button>
    </mat-chip-row>
    }
  </mat-chip-grid>
  <input [placeholder]="'keyword.type' | translate" #input [matChipInputFor]="keywordGrid"
    (matChipInputTokenEnd)="addKeyword($event)" />
</mat-form-field>
