import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {

  // Valor inicial 'false' para o menu lateral fechado
  private _sidebarState = new BehaviorSubject<boolean>(false);

  // Exposição do observable para que os componentes possam se inscrever
  sidebarState$ = this._sidebarState.asObservable();

  toggleSidebar() {
    this._sidebarState.next(!this._sidebarState.value);
  }
}
