<div
  class="btn-menu"
  [class.active]="isActive && animation"
  [style.--size]="size"
  [style.--color]="color"
>
  <div class="bar1"></div>
  <div class="bar2"></div>
  <div class="bar3"></div>
</div>
