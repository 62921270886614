<!-- <mat-form-field>
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input [formGroup]="dateRange" [rangePicker]="picker">
        <input matStartDate formControlName="start" placeholder="Start date" />
        <input matEndDate formControlName="end" placeholder="End date" />
      </mat-date-range-input>
      <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
      <mat-error *ngIf="dateRange.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
      <mat-error *ngIf="dateRange.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
    </mat-form-field> -->

<div class="pd-16 border-8">
  <header mat-dialog-title class="flex-sb">
    <p class="dialog-title">Filtro</p>
    <button mat-dialog-clos mat-button type="button" class="borderless-button cancel-button" (click)="onCancel()">
      Cancelar
    </button>
  </header>
  <div mat-dialog-content>
    <div class="align-left add-button">
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-button mat-dialog-close cdkFocusInitial type="submit" class="save-button border-8"
      *ngIf="!loadingSpinner" [disabled]="" (click)="onSubmit()">
      Salvar
    </button>
    <button mat-button type="button" class="save-button border-8" *ngIf="loadingSpinner" disabled>
      <mat-spinner [diameter]="30"></mat-spinner>
    </button>
  </div>
</div>
