import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { forkJoin, Subscription } from 'rxjs';

import { LocationService } from 'src/app/services/ws-common/location/location.service';

@Component({
  selector: 'app-country-input',
  templateUrl: './country-input.component.html',
  styleUrls: ['./country-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CountryInputComponent),
      multi: true,
    }
  ],
})
export class CountryInputComponent implements OnInit, OnDestroy, ControlValueAccessor {
  private subscriptions: Subscription = new Subscription();
  allCountries: any[] = [];
  countriesFiltered: any[] = [];
  allCities: any[] = [];
  citiesFiltered: any[] = [];
  @Input() valid!: boolean;
  @Input() multi = true;
  @Input() userLocations: any[] = [];
  @ViewChild('countryInput') countryInput!: ElementRef;
  @ViewChild('cityInput') cityInput!: ElementRef;
  selectedCities: any[] = [];
  selectedCountries: any[] = [];
  loading = false;
  public _value: any;
  public disabled!: boolean;

  constructor(private locationService: LocationService) { }

  ngOnInit(): void {
    this.getAllCountries();
  }

  countryChanges(event: any) {
    const inputValue = event.target.value || '';
    this.countriesFiltered = this._filterCountry(inputValue);
  }

  cityChanges(event: any) {
    const inputValue = event.target.value || '';
    this.citiesFiltered = this._filterCity(inputValue);
  }

  private _filterCity(value: any): any[] {
    const filterValue = value.toLowerCase();
    return this.allCities
      .filter(city => city.cityTitle.toLowerCase().includes(filterValue))
      .sort((c1, c2) => {
        const c1StartsWith = c1.cityTitle.toLowerCase().startsWith(filterValue);
        const c2StartsWith = c2.cityTitle.toLowerCase().startsWith(filterValue);
        if (c1StartsWith && !c2StartsWith) return -1;
        if (!c1StartsWith && c2StartsWith) return 1;
        return c1.cityTitle.localeCompare(c2.cityTitle);
      });
  }

  private _filterCountry(value: any): any[] {
    const filterValue = value.toLowerCase();
    return this.allCountries
      .filter(country => country.countryTitle.toLowerCase().includes(filterValue))
      .sort((c1, c2) => {
        const c1StartsWith = c1.countryTitle.toLowerCase().startsWith(filterValue);
        const c2StartsWith = c2.countryTitle.toLowerCase().startsWith(filterValue);
        if (c1StartsWith && !c2StartsWith) return -1;
        if (!c1StartsWith && c2StartsWith) return 1;
        return c1.countryTitle.localeCompare(c2.countryTitle);
      });
  }

  private getAllCountries() {
    this.loading = true;
    this.subscriptions.add(
      this.locationService.getCountries().subscribe(res => {
        this.allCountries = res.data;
        const sortedAlphabetically = this.allCountries.sort((c1, c2) => (c1.countryTitle < c2.countryTitle) ? -1 : 1);
        this.allCountries = sortedAlphabetically;
        if (this.userLocations.length === 0) {
          this.loading = false;
        }
        this.getUserData();
      }));
  }

  onChanged: any = () => { };
  onTouched: any = () => { };
  writeValue(val: any): void {
    this._value = val;
  }
  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private getUserData() {
    const observables = this.userLocations.map((city: any) => this.locationService.getInfoByCityId(city));
    forkJoin(observables).subscribe((responses: any) => {
      responses.forEach((res: any) => {
        this.selectedCities.push(res.data);
        this.allCountries.forEach(country => {
          if (country.id === res.data.countryId) {
            if (!this.selectedCountries.includes(country)) {
              this.selectedCountries.push(country);
            }
          }
        });
      });
      this.getAllCities();
    });
  }

  private getAllCities() {
    this.allCities = [];
    let obs = this.selectedCountries.map((country: any) => this.locationService.getCities(country.id));
    forkJoin(obs).subscribe((res: any) => {
      res.forEach((obj: any) => {
        this.allCities.push(...obj.data);
      });
      const sortedAlphabetically = this.allCities.sort((c1, c2) => (c1.cityTitle < c2.cityTitle) ? -1 : 1);
      this.allCities = sortedAlphabetically;
      this.loading = false;
    });
  }

  addCountry(option: any) {
    this.loading = true;
    let newCountry = option.option.value;
    if (this.selectedCountries.some(country => country.id === newCountry.id)) {
      this.loading = false;
      return;
    }
    if (!this.multi) {
      this.addSingleCountry(newCountry);
      return;
    }
    this.locationService.getCities(newCountry.id).subscribe((res: any) => {
      this.selectedCountries.push(newCountry);
      this.allCities.push(...res.data);
      const sortedAlphabetically = this.allCities.sort((c1, c2) => (c1.cityTitle < c2.cityTitle) ? -1 : 1);
      this.allCities = sortedAlphabetically;
      this.loading = false;
    });
    const indexToRemove = this.allCountries.indexOf(newCountry);
    if (indexToRemove !== -1) this.allCountries.splice(indexToRemove, 1);
    this.countryInput.nativeElement.value = '';
    this.countriesFiltered = this._filterCountry(this.countryInput.nativeElement.value);
  }

  filterCitiesBasedOnInput(userInput: string): any[] {
    const lowerCaseInput = userInput.toLowerCase();

    return this.allCities
      .filter(city => city.cityTitle.toLowerCase().includes(lowerCaseInput)) // Filtra por cidades que contenham o texto
      .sort((c1, c2) => {
        const c1StartsWith = c1.cityTitle.toLowerCase().startsWith(lowerCaseInput);
        const c2StartsWith = c2.cityTitle.toLowerCase().startsWith(lowerCaseInput);
        if (c1StartsWith && !c2StartsWith) return -1;
        if (!c1StartsWith && c2StartsWith) return 1;
        return c1.cityTitle.localeCompare(c2.cityTitle);
      });
  }

  addSingleCountry(newCountry: any) {
    if (this.selectedCountries[0]) this.removeCountry(this.selectedCountries[0]);
    this.selectedCountries = [newCountry];
    this.locationService.getCities(newCountry.id).subscribe((res: any) => {
      this.allCities = res.data;
      const sortedAlphabetically = this.allCities.sort((c1, c2) => (c1.cityTitle < c2.cityTitle) ? -1 : 1);
      this.allCities = sortedAlphabetically;
      this.loading = false;
    });
    const indexToRemove = this.allCountries.indexOf(newCountry);
    if (indexToRemove !== -1) this.allCountries.splice(indexToRemove, 1);
    this.countryInput.nativeElement.value = '';
    this.countriesFiltered = this._filterCountry(this.countryInput.nativeElement.value);
  }

  addCity(option: any) {
    let newCity = option.option.value;
    if (this.selectedCities.some(city => city.id === newCity.id)) {
      return;
    }
    if (!this.multi) {
      this.selectedCities = [newCity];
    } else {
      this.selectedCities.push(newCity);
    }
    this.cityInput.nativeElement.value = '';
    this.citiesFiltered = this._filterCity(this.cityInput.nativeElement.value);

    let ids = this.selectedCities.map(c => c.id);
    this.onTouched();
    this.onChanged(ids);
  }

  removeCountry(countryToRemove: any) {
    this.selectedCountries = this.selectedCountries.filter(country => country !== countryToRemove);
    this.allCountries.push(countryToRemove);
    this.selectedCities = this.selectedCities.filter(c => c.countryId !== countryToRemove.id);
    let ids = this.selectedCities.map(c => c.id);
    this.onTouched();
    this.onChanged(ids);
    this.allCities = this.allCities.filter(c => c.countryId !== countryToRemove.id);
    this.citiesFiltered = this._filterCity('');
  }

  removeCity(cityToRemove: any) {
    this.selectedCities = this.selectedCities.filter(c => c !== cityToRemove);
    this.allCountries.push(cityToRemove);
    let ids = this.selectedCities.map(c => c.id);
    this.onTouched();
    this.onChanged(ids);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
