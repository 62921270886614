export class TableActions {
  search?: boolean;
  filter?: boolean;
  download?: boolean;
  btnAdd?: boolean;
  btnView?: boolean;
  btnEdit?: boolean;
  btnDelete?: boolean;

  constructor(
    search?: boolean,
    filter?: boolean,
    download?: boolean,
    btnAdd?: boolean,
    btnView?: boolean,
    btnEdit?: boolean,
    btnDelete?: boolean
  ) {
    this.search = search ?? true;
    this.filter = filter ?? true;
    this.download = download ?? true;
    this.btnAdd = btnAdd ?? true;
    this.btnView = btnView ?? true;
    this.btnEdit = btnEdit ?? true;
    this.btnDelete = btnDelete ?? true;
  }

  static fromJson(json: TableActions): TableActions {
    return new TableActions(
      json.search ?? true,
      json.filter ?? true,
      json.download ?? true,
      json.btnAdd ?? true,
      json.btnView ?? true,
      json.btnEdit ?? true,
      json.btnDelete ?? true,
    );
  }
}
