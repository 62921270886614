<mat-card class="flex justify-center w-full h-full login">
  <mat-card-header class="login">
    <img alt="Síntegra Surgical Sciences" src="../../../../../assets/images/logo.svg">
    <h1>{{ 'welcome' | translate }}</h1>
    <p class="pb-6">{{ 'signin' | translate }}</p>
  </mat-card-header>
  <mat-card-content>
    <form class="form" [formGroup]="form">
      <mat-form-field appearance="fill">
        <mat-label>E-mail</mat-label>
        <input matInput type="text" formControlName="email">
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>{{ 'password' | translate }}</mat-label>
        <input matInput formControlName="password" [type]="showPassword ? 'password' : 'text'">
        <button mat-icon-button matSuffix type="button" (click)="showPassword = !showPassword" [attr.aria-label]="'hide password'"
          [attr.aria-pressed]="showPassword">
          <mat-icon>
            {{showPassword ? 'visibility_off' : 'visibility'}}
          </mat-icon>
        </button>
      </mat-form-field>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <button mat-button class="submit-button" type="submit" for="form" [disabled]="!form.valid"
      (click)="onSubmit()" *ngIf="!awaitingResponse">{{ 'login' | translate }}</button>
    <button mat-button class="submit-button loading" disabled (click)="onSubmit()" *ngIf="awaitingResponse">
      <mat-spinner></mat-spinner>
    </button>
    <div class="recovery-button pt-4">
      <button mat-button type="button" (click)="recover()">{{ 'password.recover' | translate }}</button>
    </div>
  </mat-card-actions>
</mat-card>
