import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { MenuItem } from '@interface/menu-item.interface';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent {
  @Input() menuItems: MenuItem[] | undefined;
  @Output() sidebarStatus = new EventEmitter<boolean>();
  me!: any;

  isActive: boolean = false;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.routeNavigation();
      }
    });
  }

  toggle(): void {
    this.isActive = !this.isActive;

    this.sidebarStatus.emit(this.isActive)
  }

  routeNavigation(): void {
    const breakpointSm = getComputedStyle(document.documentElement)
      .getPropertyValue('--breakpoint-sm') ?? '576px';

    if (window.innerWidth < parseInt(breakpointSm) && this.isActive) {
      this.toggle();
    }
  }
}
