import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ApiResponse } from 'src/app/models/apiResponse.model';
import { Auth } from 'src/app/models/auth.model';

import { environment } from 'src/environments/environment';

const URL = `${environment.user}/auth`;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  login(user: any) {
    return this.http.post(URL, { ...user, "keepLoggedIn": true }, { withCredentials: true });
  }

  logout() {
    return this.http.post(`${URL}/logout`, { token: localStorage.getItem('jwtToken') }, { withCredentials: true });
  }

  refreshToken(refresh: any): Observable<ApiResponse<Auth>> {
    return this.http.post<ApiResponse<Auth>>(`${URL}/refresh-token`, { token: refresh }, { withCredentials: true });
  }

  revokeToken() {
    return this.http.post(`${URL}/revoke-token`, {});
  }

  forgotPassword(email: string) {
    localStorage.clear();
    return this.http.post(`${URL}/forgot-password`, {email: email});
  }

  verifyEmail(obj: { token: string }) {
    return this.http.post(`${URL}/verify-email`, obj);
  }

  checkPasswordRecovery(obj: { token: string }) {
    return this.http.post(`${URL}/check-password-recovery`, obj);
  }

  passwordRecovery(obj: { token: string, newPassword: string }) {
    return this.http.post(`${URL}/password-recovery`, obj);
  }
}
