<mat-form-field floatLabel="always" subscriptSizing="dynamic" class="clean-input">
  <label data-test="lbl">
    <span data-test="txt" *ngIf="!optional" class="label">{{ label }}</span>
    <span data-test="txt" *ngIf="optional" class="label">{{ label }}
      <span style="color: var(--color-neutral-70);">{{ 'optional' | translate }}</span></span>
  </label>
  <input matInput autocomplete="new-password" data-test="inp" [type]="type" class="input" #input [placeholder]="placeholder"
    *ngIf="type !== 'phone' && type !== 'textarea'" (input)="inputChange($event)" [readOnly]="readonly" [value]="value" [disabled]="disabled">
  <input matInput data-test="inp" type="tel" class="input" #input [placeholder]="placeholder" *ngIf="type === 'phone'"
    (input)="inputChange($event)" [readOnly]="readonly" [value]="value" phone>
    <textarea matInput data-test="inp" class="input" #input [placeholder]="placeholder" *ngIf="type === 'textarea'"
    (input)="inputChange($event)" [readOnly]="readonly" [value]="value">{{ value }}</textarea>
  <mat-icon matSuffix (click)="exhibitPass()" *ngIf="(type === 'password' && hidePass)">
    visibility_off
  </mat-icon>
  <mat-icon matSuffix (click)="exhibitPass()" *ngIf="(type === 'text' && !hidePass)">
    visibility
  </mat-icon>
</mat-form-field>

<div *ngIf="valid" class="alert">
  {{ invalidInputMsg || 'invalid.input' | translate }}
</div>
