<div id="sidebar" [class.active]="isActive">
  <div class="header">
    <div class="logo"></div>
    <app-svg-icon class="close-button" [iconName]="'close'" (click)="toggle()"></app-svg-icon>
  </div>
  <div class="content">
    <ul>
      <li *ngFor="let item of menuItems">
        <app-tooltip [value]="item.Name" [disable]="isActive" [distance]="'1rem'">
          <a [routerLink]="[item.Route]" [routerLinkActive]="['link-active']" #linkActive="routerLinkActive">
            <app-svg-icon [iconName]="item.Icon" [fill]="linkActive.isActive"></app-svg-icon>
            <span *ngIf="isActive">{{ item.Name }}</span>
          </a>
        </app-tooltip>
      </li>
    </ul>
  </div>
</div>
