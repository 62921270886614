import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormControl } from '@angular/forms';

@Component({
  selector: 'app-multi-select-input',
  templateUrl: './multi-select-input.component.html',
  styleUrl: './multi-select-input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectInputComponent),
      multi: true,
    }
  ],
})
export class MultiSelectInputComponent implements OnInit, ControlValueAccessor {
  @Input() toppings = new FormControl<any>(null);
  defaultValues: any[] = [];
  public _value: any;
  public disabled!: boolean;
  @Input() label: any = null;
  @Input() placeholder: any = null;
  @Input() data: any[] = [];
  @Input() valid!: boolean;
  @Input() optional = false;
  @Input() invalidInputMsg: string = 'Invalido' //= this.translate.instant('invalid.input');
  @Output() onSelect: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit(): void {
    this.populate();
  }

  onChanged: any = (option: any) => { };
  onTouched: any = () => { };

  writeValue(val: any): void {
    this._value = val;
  }
  registerOnChange(fn: (option: any) => void): void {
    this.onChanged = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  populate() {
    this.defaultValues = this.data.filter((d: any) => (this.toppings.getRawValue() ?? []).includes(d.id));
  }

  selectOption(option: any) {
    const selected = (this.toppings.getRawValue() ?? []).map((obj: any) => obj.id);
    this.writeValue(selected);
    this.onChanged(selected);
    this.onTouched();
    this.onSelect.emit(selected);
  }
}
