<div class="clean-input flex direction-col g-3 pb-3">
  <label for="picInput" *ngIf="!optional" class="label">{{ highlight ? ('image.highlight' | translate) : ('image' |
    translate) }}</label>
  <label data-test="txt" *ngIf="optional" class="label">{{ multiple ? ('images' | translate) : ('image' | translate) }}
    <span style="color: var(--color-neutral-70);">{{ 'optional' | translate }}</span></label>
  <div style="position: relative;">
    <div class="upload-button flex items-center justify-center">
      <mat-icon class="upload-icon">upload_file</mat-icon>
      <p>
        {{ multiple ? ('images.upload' | translate) : ('image.upload' | translate) }}
      </p>
    </div>
    <input #picInputRef id="picInput" type='file' accept="image/*" (change)="changeImage($event)" (click)="touched()">
    <p *ngIf="error" class="error-msg pt-2">{{ error }}</p>
  </div>
  <p class="details">{{ 'image.upload.detail' | translate }}</p>
  <p class="details">{{ 'image.upload.resolution' | translate }}</p>
  <div class="flex items-end" *ngIf="picture && !multiple">
    <div class="flex g-3 items-end">
      <div class="flex direction-col">
        <img [src]="picture.file" alt="Image Preview" class="image-preview" *ngIf="picture.file">
        <p class="file-name">{{ hasName ? picture.title : ('image.default' | translate) }}</p>
      </div>
      <mat-icon class="done">done</mat-icon>
    </div>
    <mat-icon class="close" (click)="deleteImage(picture)">close</mat-icon>
  </div>
  <div *ngIf="multiplePicture && multiple" class="wrap flex g-4">
    <div *ngFor="let p of multiplePicture" class="flex items-end justify-start" style="flex: 1 1 48%;">
      <div class="flex g-3 items-end" >
        <div class="flex direction-col">
          <img [src]="p.file" alt="Image Preview" class="image-preview" *ngIf="p.file">
          <p class="file-name">{{ p.title }}</p>
        </div>
        <mat-icon class="done">done</mat-icon>
      </div>
      <mat-icon class="close" (click)="deleteImage(p)">close</mat-icon>
    </div>
  </div>
</div>

<div *ngIf="valid" class="alert">
  {{ 'invalid.input' | translate }}
</div>
