import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterTablePipe } from './filter-table.pipe';



@NgModule({
  declarations: [
    FilterTablePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    FilterTablePipe
  ]
})
export class FilterTableModule { }
