import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-slide-toggle-input',
  templateUrl: './slide-toggle-input.component.html',
  styleUrl: './slide-toggle-input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SlideToggleInputComponent),
      multi: true,
    }
  ],
})
export class SlideToggleInputComponent implements ControlValueAccessor {
  public _value: any;
  public disabled!: boolean;
  @Input() label!: string;
  @Input() value!: boolean;
  @Input() toppings = new FormControl<any>(null);

  onChanged: any = () => {};
  onTouched: any = () => {};

  writeValue(val: any): void {
    this._value = val;
  }
  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  checked() {
    this.toppings.setValue(!this.toppings.value);
    this.value = !this.value;
    this.onTouched();
    this.onChanged(this.value);
  }
}
