import { AfterViewInit, Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
  styleUrl: './date-input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateInputComponent),
      multi: true,
    }
  ],
})
export class DateInputComponent implements ControlValueAccessor, AfterViewInit {

  public _value: any;
  public disabled!: boolean;
  @Input() value: any = null;
  @Input() title!: string;
  @Input() placeholder!: string;
  @Input() valid!: boolean;
  @Input() time = false;
  @Input() optional = false;
  @Input() invalidInputMsg: string = 'Invalido' //= this.translate.instant('invalid.input');
  hourInputId = Math.random().toString(36).substr(2, 9);

  onChanged: any = (date: any) => { };
  onTouched: any = () => { };

  constructor(private translate: TranslateService) { }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this._value)
        this.extractHoursAndMinutes(this._value);
    });
  }

  writeValue(val: any): void {
    this._value = val;
  }
  registerOnChange(fn: (option: any) => void): void {
    this.onChanged = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  selectDate(date: any) {
    const isoDateString = date.value.toISOString();
    const finalDateValue = this.time ? this.addTimeToDateString(isoDateString) : isoDateString;

    this.extractHoursAndMinutes(finalDateValue)
    this.writeValue(finalDateValue);
    this.onChanged(finalDateValue);
    this.onTouched();
  }

  addTimeToDateString(isoDateString: string): string {
    const timeInput = document.getElementById(this.hourInputId) as HTMLInputElement;
    if (!timeInput) return isoDateString; // Return original value if time input not found

    if (!timeInput || timeInput.value.trim() === '') {
      this.valid = false;
      const date = new Date(isoDateString);
      date.setHours(0, 0, 0, 0);
      return date.toISOString();
    }
    const timeValue = timeInput.value.trim();
    const regex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
    if (!regex.test(timeValue)) {
      this.valid = true;
      this.invalidInputMsg = this.translate.instant('hour.invalid');
      const date = new Date(isoDateString);
      date.setHours(0, 0, 0, 0);
      return date.toISOString();
    }
    this.valid = false;

    const timeParts = timeValue.split(':');
    const hours = parseInt(timeParts[0]);
    const minutes = parseInt(timeParts[1]);

    if (minutes < 0 || minutes > 59) {
      this.valid = true;
      this.invalidInputMsg = this.translate.instant('hour.invalid');
      const date = new Date(isoDateString);
      date.setHours(0, 0, 0, 0);
      return date.toISOString();
    }

    const date = new Date(isoDateString);
    date.setHours(0, 0, 0, 0);
    date.setHours(hours);
    date.setMinutes(minutes);

    return date.toISOString();
  }

  extractHoursAndMinutes(isoDateString: string): string {
    const date = new Date(isoDateString);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const formattedHours = hours < 10 ? '0' + hours : hours.toString();
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes.toString();
    const timeInput = document.getElementById(this.hourInputId) as HTMLInputElement;
    if (timeInput) {
      timeInput.value = formattedHours + ':' + formattedMinutes;
    }
    return formattedHours + ':' + formattedMinutes;
  }

  onInputChange(event: any) {
    let sanitizedValue = event.target.value.replace(/\D/g, '');
    if (sanitizedValue.length > 4) {
      sanitizedValue = sanitizedValue.substring(0, 4);
    }
    if (sanitizedValue.length > 2) {
      sanitizedValue = sanitizedValue.substring(0, 2) + ':' + sanitizedValue.substring(2);
    }
    event.target.value = sanitizedValue;
    this._value = this.addTimeToDateString(this._value)
    this.onChanged(this._value);
    this.onTouched();
  }
}
