<div class="mt-6 mx-6 flex direction-col items-center" style="position: relative;">
    <button type="button" class="close" [mat-dialog-close]="false">
      {{ 'close' | translate }}
    </button>
  <mat-icon>warning</mat-icon>
  <div mat-dialog-content>
    <p class="message pb-8 pt-4">{{data.message}}</p>
  </div>
</div>
<div mat-dialog-actions [align]="'center'" class="mb-6">
  <button mat-raised-button class="mb-6 actions confirm" type="button" [mat-dialog-close]="true">
    {{data.confirmCaption}}
  </button>
  <button mat-raised-button class="mb-6 actions cancel" type="button" [mat-dialog-close]="false">
    {{data.cancelCaption}}
  </button>
</div>
