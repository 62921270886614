import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrl: './tooltip.component.scss'
})
export class TooltipComponent implements OnInit {
  @Input() value: string = 'Default';
  @Input() position: string = 'right';
  @Input() disable: boolean = false;
  @Input() distance: string = '0.15625rem';

  isShow: boolean = true;
  positions = [
    'right',
    'left',
    'top',
    'bottom'
  ];

  ngOnInit(): void {
    if (!this.positions.includes(this.position)) {
      this.position = 'right';
    }
  }
}
