import { Component, ElementRef, Input, ViewChild, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'src/app/services/dialogs/dialog.service';

@Component({
  selector: 'app-upload-image-input',
  templateUrl: './upload-image-input.component.html',
  styleUrl: './upload-image-input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UploadImageInputComponent),
      multi: true,
    }
  ],
})
export class UploadImageInputComponent implements ControlValueAccessor {

  @ViewChild('picInputRef', { static: false }) picInput!: ElementRef;
  picture!: any;
  multiplePicture: any[] = [];
  error = '';
  @Input() highlight = false;
  @Input() optional = false;
  @Input() multiple = false;
  @Input() valid!: boolean;
  @Input() hasName = true;
  @Input() invalidInputMsg: string = this.translate.instant('invalid.input');

  private onChange: any = () => { };
  private onTouched: any = () => { };

  constructor(private translate: TranslateService, private dialog: DialogService) { }

  changeImage(e: any) {
    if (e.target.files && e.target.files[0]) {
      if (e.target.files[0].size >= 5242880) {
        this.error = this.translate.instant('image.size.5mb');
        this.onChange(null);
        return;
      }

      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (event) => {
        if (event.target?.result) {
          let str = event.target?.result as string;
          if (!str.startsWith("data:image/") || str.startsWith("data:image/tiff") || str.startsWith("data:image/svg+xml")) {
            this.error = this.translate.instant('image.type.not');
            this.onChange(null);
          } else {
            if (this.multiple) {
              this.multiplePicture.push({ file: event.target?.result, highlight: false, title: this.picInput.nativeElement.files[0].name });
              this.onChange(this.multiplePicture);
            } else {
              this.picture = this.hasName ? { file: event.target?.result, highlight: this.highlight, title: this.picInput.nativeElement.files[0].name } : event.target?.result;
              this.onChange(this.picture);
            }
            this.error = '';
          }
        }
      }
    }
  }

  deleteImage(pic: any) {
    this.dialog.confirmDialog({
      title: this.translate.instant('image.delete'),
      message: this.translate.instant('image.delete'),
      confirmCaption: this.translate.instant('yes'),
      cancelCaption: this.translate.instant('no'),
    })
      .subscribe((yes) => {
        if (yes) {
          if (this.multiple) {
            const index = this.multiplePicture.indexOf(pic);
            if (index !== -1) {
              this.multiplePicture.splice(index, 1);
            }
            this.onChange(this.multiplePicture);
            this.picInput.nativeElement.value = '';
          } else {
            this.picture = null;
            this.onChange(null);
            this.picInput.nativeElement.value = '';
          }
        }
      });
  }

  writeValue(obj: any): void {
    if (obj && this.multiple) {
      this.multiplePicture = obj;
    } else if (obj) {
      this.picture = obj;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  touched() {
    this.onTouched();
  }

  setDisabledState?(isDisabled: boolean): void { }
}
