<div class="flex wrap justify-between schedule-section" *ngFor="let item of schedules; let i=index" (click)="touch()">
  <div class="flex w-full1 items-end">
    <mat-icon class="hour">schedule</mat-icon>
    <mat-form-field floatLabel="always" subscriptSizing="dynamic"
      class="clean-input date-input-field flex direction-col">
      <label class="label">
        <span>{{ 'schedule' | translate }}</span>
      </label>
      <div class="flex items-center">
        <input [(ngModel)]="item.startDate" data-test="inp" matInput (input)="onDateInputChange(item, $event, true)"
          [placeholder]="'dd/mm/aaaa' | translate">
      </div>
    </mat-form-field>
    <mat-form-field floatLabel="always" subscriptSizing="dynamic" class="clean-input time-input-field">
      <label class="label">
        <span>⠀</span>
      </label>
      <input [(ngModel)]="item.startHour" data-test="inp" matInput [placeholder]="'00:00' | translate" maxlength="5" [id]="hourInputId" (input)="onHourInputChange(item, $event, true)">
    </mat-form-field>

    <mat-icon class="arrow">arrow_forward</mat-icon>
    <mat-form-field floatLabel="always" subscriptSizing="dynamic"
      class="clean-input date-input-field flex direction-col">
      <label class="label">
        <span>⠀</span>
      </label>
      <div class="flex items-center">
        <input [(ngModel)]="item.endDate" data-test="inp" matInput (input)="onDateInputChange(item, $event, false)"
          [placeholder]="'dd/mm/aaaa' | translate">
      </div>
    </mat-form-field>
    <mat-form-field floatLabel="always" subscriptSizing="dynamic" class="clean-input time-input-field">
      <label class="label">
        <span>⠀</span>
      </label>
    <input [(ngModel)]="item.endHour" data-test="inp" matInput [placeholder]="'00:00' | translate" maxlength="5" [id]="hourInputId" (input)="onHourInputChange(item, $event, false)">
    </mat-form-field>
  </div>
  <!-- <div class="alert w-full" *ngIf="touched && (!item.endHour || !item.startHour)">
    {{ 'invalid.input.hour' | translate }}
  </div>
  <div class="alert w-full" *ngIf="touched && (!item.endDate || item.endHour)">
    {{ 'invalid.input.date' | translate }}
  </div> -->
  <div>
    <div class="flex no-wrap schedule-text w-full1">
      <mat-form-field floatLabel="always" subscriptSizing="dynamic" class="clean-input w-full title-input">
        <label class="label">
          <span>⠀</span>
        </label>
        <input [(ngModel)]="item.languages[0].title" data-test="inp" matInput [placeholder]="'title' | translate">
      </mat-form-field>
      <mat-form-field floatLabel="always" subscriptSizing="dynamic" class="clean-input w-full title-input">
        <label class="label">
          <span>⠀</span>
        </label>
        <input [(ngModel)]="item.languages[0].description" data-test="inp" matInput [placeholder]="'text' | translate">
      </mat-form-field>
    </div>
  </div>
  <button class="remove-btn" *ngIf="schedules.length > 1" (click)="removeScheduleElement(i)">
    <mat-icon class="mt-1">remove</mat-icon>
  </button>

</div>
<button class="add-btn mt-5" (click)="addScheduleElement()">
  <mat-icon class="mt-1">add</mat-icon>
</button>
