<div class="tooltip">
  <span
    *ngIf="!disable"
    class="tooltip-text"
    [ngClass]="position"
    [style.--distance]="distance"
    aria-hidden="true"
  >
    {{ value }}
  </span>
  <ng-content></ng-content>
</div>
