<div class="content">
  <section class="img">
    <img src="../../../assets/img/sintegra-login-right.png">
  </section>
  <section class="forms">
    <app-login-form *ngIf="login" (onRecover)="login = $event"></app-login-form>
    <app-password-recovery *ngIf="!login" (onLogin)="login = $event"></app-password-recovery>
  </section>
  <p class="pb-6 app-version pe-3">v{{ appVersion }}</p>
</div>
