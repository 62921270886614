<mat-form-field floatLabel="always" subscriptSizing="dynamic" class="clean-input">
  <label class="label">{{ 'country' | translate }}</label>
  <mat-chip-grid #countryGrid aria-label="Country selection">
    @for (country of selectedCountries; track country) {
    <mat-chip-row (removed)="removeCountry(country)">
      {{ country.countryTitle }}
      <button matChipRemove [attr.aria-label]="'remove ' + country">
        <mat-icon>close</mat-icon>
      </button>
    </mat-chip-row>
    }
  </mat-chip-grid>
  <mat-spinner mode="indeterminate" style="margin:0 auto;" [diameter]="18" *ngIf="loading"></mat-spinner>
  <input [placeholder]="'country.select' | translate" #countryInput matInput [matChipInputFor]="countryGrid"
    [matAutocomplete]="country" (input)="countryChanges($event)" />
  <mat-autocomplete requireSelection #country="matAutocomplete" (optionSelected)="addCountry($event)">
    @for (country of countriesFiltered.slice(0, 50); track country) {
    <mat-option [value]="country">{{ country.countryTitle }}</mat-option>
    }
  </mat-autocomplete>
  <!-- <mat-icon matSuffix>domain</mat-icon> -->
</mat-form-field>

<mat-form-field *ngIf="selectedCountries.length > 0 && !loading" floatLabel="always" subscriptSizing="dynamic"
  class="clean-input">
  <label class="label">{{ 'city' | translate }}</label>
  <mat-chip-grid #cityGrid aria-label="city selection">
    @for (city of selectedCities; track city) {
    <mat-chip-row (removed)="removeCity(city)">
      {{ city.cityTitle }} - {{ city.stateShort }}
      <button matChipRemove [attr.aria-label]="'remove ' + city">
        <mat-icon>close</mat-icon>
      </button>
    </mat-chip-row>
    }
  </mat-chip-grid>
  <input [placeholder]="'city.select' | translate" #cityInput [matChipInputFor]="cityGrid" [matAutocomplete]="city"
    (input)="cityChanges($event)" />
  <mat-autocomplete requireSelection #city="matAutocomplete" (optionSelected)="addCity($event)">
    @for (city of citiesFiltered.slice(0, 50); track city) {
    <mat-option [value]="city">{{ city.cityTitle }} - {{ city.stateShort }}</mat-option>
    }
  </mat-autocomplete>
</mat-form-field>

<div *ngIf="valid" class="alert">
  {{ 'invalid.input' | translate }}
</div>
